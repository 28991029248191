// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe: {
    publicKey: 'pk_test_51Ml4fLSIrVrClaUzzc6ExwcvJu458U4MTk8VS61ewTDEaWPAxSI5Nah2TwFcMfByNagiDiR4gJEOzp25NCiOD7pe00FpxL3PZ5',
  },
  apiUrl: "http://localhost:4200",

  baseUrl: "https://api.cuelab.net/api/",
  //live
  //redirectUrl: "https://app.imparter.ai/simple/first-page",
  //local
  redirectUrl: "http://localhost:4200/dashboard",

   facebookAppId: "3335000936724506",
  //facebookAppId: '3335000936724506',


  firebaseConfig: {
    apiKey: "AIzaSyDK5pbpMvY0hxw3lrZCXi-jXETASz8IWIU",
    authDomain: "impartr.firebaseapp.com",
    // databaseURL: "http://localhost:4200/",
    projectId: "impartr",
    storageBucket: "impartr.appspot.com",
    messagingSenderId: "695072061274",
    appId: "1:695072061274:web:396c4fcc504c6eb7f82563",
    measurementId: "G-1TV3QM1XQW",
    vapidKey: "BJmbatGP7sALDJaYkmGZ-yUdG5ZPcLQuALWsz1qxTLDoCUpTYx9aJFo1bI7WH1fXom1"
  }

  //facebookAppId: "3335000936724506" // App ID for CueLab

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
