<div class="container-fluid">
    <br>
    <div class="row">
        <!-- Owner Organizations Card -->
        <div class="col-md-2"></div>
        <div class="col-md-4" style="background-color: #EFF6FF;border-radius: 20px;">
            <div>
                <div style="padding-top: 10px;">
                    <h5>Owner (Selft - Created)</h5>
                </div>
                <br>
                <br>
                <div>
                    <div class="card" *ngFor="let data of ownerOrganizations" style="margin-top: -15px">
                        <div class="row mb-2">
                            <div class="col-md-3">
                                <div class="circle-container">
                                    <svg height="100" width="100">
                                        <circle cx="50" cy="50" r="25" fill="#C7C7C7" />
                                        <text x="60%" y="50%" dominant-baseline="middle" text-anchor="middle"
                                            fill="#3285FF" font-size="15px">{{ getFirstAndLastCharacters(data.name)
                                            }}</text>
                                    </svg>
                                </div>
                            </div>
                            <div class="col-md-5" style="margin-top: 3%;">
                                <div class="row">
                                    <span style="font-weight: 500;">{{ data.name }}</span>
                                </div>
                                <div class="row">
                                    <span style="font-size: small;">{{ data.domain }}</span>
                                </div>
                            </div>
                            <div class="col-md-4" style="margin-top: 3%;">
                                <div class="row">
                                    <span style="font-size: smaller;color: #3285FF;">Self Created</span>
                                </div>
                                <div class="row">
                                    <span><button type="button" class="my-button" (click)="joinOrganization(data)">
                                            Join
                                        </button></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Member Organizations Card -->
        <div class="col-md-4" style="background-color: #EFF6FF;border-radius: 20px;">
            <div>
                <div style="padding-top: 10px;">
                    <h5>Added By Another</h5>
                </div>
                <br>
                <br>
                <div class="card" *ngFor="let data of memberOrganizations" style="margin-top: -15px">
                    <div class="row mb-2">
                        <div class="col-md-3">
                            <div class="circle-container">
                                <svg height="100" width="100">
                                    <circle cx="50" cy="50" r="25" fill="#C7C7C7" />
                                    <text x="60%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#3285FF"
                                        font-size="15px">{{ getFirstAndLastCharacters(data.name) }}</text>
                                </svg>
                            </div>
                        </div>
                        <div class="col-md-5" style="margin-top: 3%;">
                            <div class="row">
                                <span style="font-weight: 500;">{{ data.name }}</span>
                            </div>
                            <div class="row">
                                <span style="font-size: small;">{{ data.domain }}</span>
                            </div>
                        </div>
                        <div class="col-md-4" style="margin-top: 3%;">
                            <div class="row">
                                <span style="font-size: smaller;color: #3285FF;">Self Created</span>
                            </div>
                            <div class="row">
                                <span><button type="button" class="my-button" (click)="joinOrganization(data)">
                                        Join
                                    </button></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>