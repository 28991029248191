import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationsService } from '../../services/organizations.service';

@Component({
  selector: 'app-create-or-join-organizations',
  templateUrl: './create-or-join-organizations.component.html',
  styleUrl: './create-or-join-organizations.component.scss'
})
export class CreateOrJoinOrganizationsComponent {

  constructor(private organizationsService: OrganizationsService,
    private _router: Router,
  ) { }

  allOrganizations = [];
  public ownerOrganizations = [];
  public memberOrganizations = [];
  orgData = [];

  async ngOnInit() {

    const userId = await localStorage.getItem('userId');
    this.getAllOrganizationsByUserId(userId);

  }

  getFirstAndLastCharacters(word: string): string {
    if (!word || word.length === 0) {
      return '';
    }
    const firstChar = word.charAt(0).toUpperCase();
    const lastChar = word.charAt(word.length - 1).toUpperCase();
    return `${firstChar}${lastChar}`;
  }

  getAllOrganizationsByUserId(userId) {
    this.organizationsService.getOrganizatonsData(userId).subscribe((data: any) => {
      console.log("data : ", data);
      this.allOrganizations = data['Response'];
      console.log("this.allOrganizations : "+this.allOrganizations);
      this.ownerOrganizations = this.allOrganizations.filter(org => org.roleId === 'Owner');
      this.memberOrganizations = this.allOrganizations.filter(org => org.roleId === 'Member' || org.roleId === 'Admin');
      
      console.log("this.memberOrganizations data is 45",this.memberOrganizations)
    })
  }

  async joinOrganization(data:any){
    console.log("data : ",data);
    await localStorage.setItem('org_name',data.name);
    await localStorage.setItem('org_id',data.id);
    await localStorage.setItem('org_code',data.orgCode);
    await localStorage.setItem('org_parentId',data.parentId);
    await localStorage.setItem('org_ownerUserId',data.ownerUserId);
    await localStorage.setItem('org_ownerMobileNumber',data.ownerMobileNumber);
    await localStorage.setItem('user_roleId',data.roleId);
  
    this._router.navigateByUrl('/dashboard')
  }
}
