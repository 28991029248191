import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'


export class update {
  userMobileNumber:any;
  email:any;
  firstName:any;
  lastName:any;
  uid:any;
  company_Name:any;
  company_Website:any;
  address:any;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title: any;
  @Input() items: any[];
  @Input() active_item: string;
  @Input() subTitle : string;

  userDetail!: FormGroup;
  profileImageUrl: string = ''; // Store the URL of the profile image
  isModalOpen: boolean = false;
  profileImageFile!: File | null;
  emailid: any;
  firstname: any;
  lastname: any;
  mobileNum: any;
  company: any;
  update : update = new update()
  constructor(
    private formBuilder: FormBuilder,
    private router:Router) {
  }

  ngOnInit() {  }
  formInit1(){
    this.userDetail = this.formBuilder.group({
      email: [''],
      firstName: [''],
      lastName: [''],
      mobile: [''],
      companyName: [''],
      website: [''],
      address: [''],  
      profileImage:['']    
    });
  }

 

logout(){
  localStorage.clear();
  // this.router.navigateByUrl('/sign-in');
}

switchtToCreateOrgJoin() {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You want to switch Organization',
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'No, keep it',
    confirmButtonText: 'Yes!',
  }).then((result) => {
    if (result.value) {
      console.log("result", result.value);
      this.router.navigateByUrl('/create-or-join-organizations')
    }

  })
}



}
