import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject, Subscription, interval } from 'rxjs';
// import { CoreConfigService } from '@core/services/config.service';
//import CoreConfigService from '@core/services/config.service';

import { ActivatedRoute, Router } from '@angular/router';
import { first, takeUntil, takeWhile } from 'rxjs/operators';
import { LoginService } from '../../services/login.service';
import { LoginQRData } from '../../models/loginQrData';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {



  //  Public
  public coreConfig: any;
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public passwordTextType: boolean;
  QRData: LoginQRData = new LoginQRData();
  alive: boolean;
  qrString: string;
  text: string;
  refreshCounter: number;
  ischeckeralive: boolean;
  deviceInfo: any;
  ipAddress: any

  // Private
  // private _unsubscribeAll: Subject<any>;

  private qrCodeRefreshInterval = 10000; // Refresh QR code every 10 seconds
  private refreshButtonVisibilityInterval = 30000; // Show refresh symbol after 1 minute
  private refreshSymbolVisible = false;
  private timerSubscription: Subscription;
  public showRefreshButton: boolean = false;
  public isBlurred: boolean = false;
  public showQRCode: boolean;

  constructor(
    //  private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private qrService: LoginService,
    private deviceService: DeviceDetectorService,
    private http: HttpClient

  ) { 

    setTimeout(() => {
      this.showQRCode = true;
    }, 5000); // 5000 milliseconds = 5 seconds
  
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        console.log('th data', data);
        this.ipAddress = data.ip
        console.log("this.ipAddress : ",this.ipAddress);
        
      })
  }


  recurringFunction() {
    const source = interval(this.qrCodeRefreshInterval).pipe(
      takeWhile(() => this.alive)
    );


    const source1 = interval(5000).pipe(
      takeWhile(() => this.ischeckeralive)
    );



    source.subscribe(() => {
      if (this.refreshCounter == 10) {
        this.alive = false;
        this.ischeckeralive = false;
        this.refreshCounter = 0;
      } else {
        this.generateString();
        this.refreshCounter++;
        console.log("this.ipAddress : ",this.ipAddress);
        this.saveQRData(this.text, this.ipAddress);

      }
    });


    // Show refresh symbol after 1 minute
    interval(this.refreshButtonVisibilityInterval).subscribe(() => {
      this.refreshSymbolVisible = true;
    });

    source1.subscribe(() => {
      this.checkUserVerification(this.text, this.ipAddress);
    });

  }


  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType;
  }


  saveQRData(secString: string, ipAddress: string) {
    this.QRData = new LoginQRData();
    this.QRData.secString = secString;
    this.QRData.userIpAddress = ipAddress;
    // this.QRData.browser = this.deviceInfo.browser;
    // this.QRData.browserVersion =  this.deviceInfo.browser_version;
    // this.QRData.osVersion =  this.deviceInfo.os_version;
    // this.QRData.os =  this.deviceInfo.os;

    this.qrService.saveLoginQRData(this.QRData).subscribe(data => {
      console.log('in saving process.....');
      if (data['status'] == 200) {
        console.log('Saved!!!');
      } else if (data['status'] == 404) {
        console.log('Error!!!');
      }
    });
  }


  checkUserVerification(secString: string, ipAddress: string) {
    this.QRData.secString = secString;
    this.QRData.userIpAddress = ipAddress
    console.log('ipAddress' ,ipAddress);
    

    this.qrService.checkUserVerification(this.QRData).subscribe(data => {
      if (data['StatusCode'] == 200) {
        this.alive = false;
        this.ischeckeralive = false;

        console.log('User Verified!!! ');
        localStorage.setItem('userId', data['Response'][0].id);
        localStorage.setItem('user_firstName', data['Response'][0].firstName);
        localStorage.setItem('user_lastName', data['Response'][0].lastName);
        this._router.navigateByUrl('/create-or-join-organizations');

      } else if (data['StatusCode'] == 201) {
        console.log('User NOT Verified!!!');
      }
    }, (err) => {
    }
    )
  }



  generateString() {

    this.text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 10; i++) {
      this.text = this.text + possible.charAt(Math.floor(Math.random() * possible.length));
    }

    this.qrString = this.text + '/' + this.ipAddress + '/' + this.deviceInfo.browser
    console.log("qr string-->>"+this.qrString);
    
  }


    onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    // Login
    this.loading = true;

  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
   
    this.alive = true;
    this.ischeckeralive = true;
    this.refreshCounter = 0;
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.generateString();
    this.saveQRData(this.text, this.ipAddress);
    this.refreshCounter++;
    //this.startTimer();
    this.recurringFunction();

  }

  /**
   * On destroy
   */
  // ngOnDestroy(): void {
  //   // Unsubscribe from all subscriptions
  //   this._unsubscribeAll.next();
  //   this._unsubscribeAll.complete();
  // }

  // startRefreshButtonTimer() {
  //   this.timerSubscription = interval(this.refreshButtonVisibilityInterval).subscribe(() => {
  //     this.showRefreshButton = true;
  //     // Stop timer after showing refresh button
  //     this.stopTimer();
  //   });
  // }


  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  refreshQRCode() {
    this.alive = true;
    this.ischeckeralive = true;
    this.refreshCounter = 0;
    this.generateString();
    //this.saveQRData(this.text,'Qrcode' );//localStorage.getItem('device-uuid')
    //this.refreshCounter++;
    this.showRefreshButton = false; // Hide refresh button after clicking
    // this.startRefreshButtonTimer(); // Restart timer after clicking
    this.isBlurred = false;
    this.recurringFunction();
  }


  ngOnDestroy(): void {
    this.stopTimer();
  }

  // startTimer() {
  //   this.timerSubscription = interval(this.refreshButtonVisibilityInterval).subscribe(() => {
  //     this.showRefreshButton = true;
  //     this.isBlurred = true;

  //     // Stop timer after showing refresh button and applying blur effect
  //     this.stopTimer();
  //   });
  // }

}
