import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MentionModule } from 'angular-mentions';
// // for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// // for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// // for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';  
import { LoginComponent } from './auth/login/login.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { AnalyticsModule } from 'angular-analytics';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { NgOtpInputModule } from 'ng-otp-input';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { QRCodeModule } from 'angularx-qrcode';
import { initializeApp } from "firebase/app";
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CreateOrJoinOrganizationsComponent } from './auth/create-or-join-organizations/create-or-join-organizations.component';




initializeApp(environment.firebaseConfig);
@NgModule({
  
  declarations: [
    AppComponent,
    LoginComponent,
    CreateOrJoinOrganizationsComponent,
    
  ],
  imports: [
  // AnalyticsModule.forRoot({ trackingCode: 'G-1TV3QM1XQW' }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    BrowserAnimationsModule,
    OverlayModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule, 
    DropdownModule,
    DragDropModule,
    NgOtpInputModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    TimepickerModule.forRoot(),
    NgxDropzoneModule,
    MentionModule,
    NgMultiSelectDropDownModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    QRCodeModule
    
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CookieService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, //keeps the user signed in
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('3335000936724506'),
          },

        ]
      } as SocialAuthServiceConfig
    },SocialAuthService,DatePipe,
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
