<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0 justify-content-center">
    <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
      <div class="col-12 col-sm-8 align-items-center col-md-6 col-lg-12 px-xl-2 mx-auto" style="align-items: center;" r>
        <img src="../../../assets//images/Cuelab-Universal Image-1.png" height="50px" width="200px"
          style="margin-left: 20%;">
        <h2 class="card-title font-weight-bold mb-1" style="text-align: center;">Welcome to CueLab! 👋</h2>
        <div class="card card-developer-meetup">
          <div class="card-body text-center">
            <div>
              <div class="row">
                <div class="col-md-4"></div>
                <div class="card col-md-4" *ngIf="alive" style="position: relative;">
                  <!-- <div *ngIf="showQRCode"> -->
                  <qrcode [qrdata]="qrString" style="pointer-events: none;margin-left: -60px;" [width]="200"
                    [errorCorrectionLevel]="'M'" [ngStyle]="{'filter': isBlurred ? 'blur(5px)' : 'none'}"></qrcode>
                  <!-- </div> -->
                </div>
                <div class="card col-md-4" *ngIf="!alive">
                  <button id="refresh" title="Click to refresh QR Code..." (click)="refreshQRCode()"
                    style="display: flex; justify-content: center; align-items: center; height: 200px; width: 200px; border: none; background: none; margin-left: -60px;">
                    <img style="height: 100%; width: 100%;" src="../../../assets/images/Group 26 (1).png" alt="QR Code">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>