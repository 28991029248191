
import { Routes } from "@angular/router";
import { AuthGuard } from "src/app/auth.guard";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "integrations",
    loadChildren: () =>
      import("../../components/integrations/integrations.module").then(
        (m) => m.IntegrationsModule
      ),
  },
 // org_id
// if ((localStorage.getItem('org_id') == "'4779386f-ad16-468f-a027-3a6d0dc9a41a'"))
  {
    path: "admindashboard/admindashboard",
    loadChildren: () =>
      import("../../components/admindashboard/admindashboard.module").then(
        (m) => m.AdmindashboardModule
      ),
  },
  {
    path: "admindashboard/plan-details",
    loadChildren: () =>
      import("../../components/plan-details/plan-details.module").then(
        (m) => m.PlanDetailsModule
      ),
  },
  {
    path: "admindashboard/report",
    loadChildren: () =>
      import("../../components/report/report.module").then(
        (m) => m.ReportModule
      ),
  },
  {
    path: "admindashboard/graph",
    loadChildren: () =>
      import("../../components/graph/graph.module").then(
        (m) => m.GraphModule
      ),
  },
  {
    path: "admindashboard/coupon-code",
    loadChildren: () =>
      import("../../components/coupon-code/coupon-code.module").then(
        (m) => m.CouponCodeModule
      ),
  },

  {
    path: "admindashboard/google-map",
    loadChildren: () =>
      import("../../components/google-map/google-map.module").then(
        (m) => m.GoogleMapModule
      ),
  },

  {
    path: "organization/org-members",
    loadChildren: () =>
      import("../../components/org-member/org-member.module").then(
        (m) => m.OrgMemberModule
      ),
  },
  {
    path: "organization/add-organization-member",
    loadChildren: () =>
      import(
        "../../components/add-organization-member/add-organization-member.module"
      ).then((m) => m.AddOrganizationMemberModule),
  },
  {
    path: "organization/update-member",
    loadChildren: () =>
      import("../../components/update-member/update-member.module").then(
        (m) => m.UpdateMemberModule
      ),
  },
  {
    path: "organization/leads-for-member",
    loadChildren: () =>
      import("../../components/leads-for-member/leads-for-member.module").then(
        (m) => m.LeadsForMemberModule
      ),
  },
  {
    path: "organization/members-in-organization",
    loadChildren: () =>
      import(
        "../../components/members-in-organization/members-in-organization.module"
      ).then((m) => m.MembersInOrganizationModule),
  },
  {
    path: "facebook-pages",
    loadChildren: () =>
      import("../../components/facebook-pages/facebook-pages.module").then(
        (m) => m.FacebookPagesModule
      ),
  },
  {
    path: "facebook-connected-pages",
    loadChildren: () =>
      import(
        "../../components/facebook-connected-pages/facebook-connected-pages.module"
      ).then((m) => m.FacebookConnectedPagesModule),
  },
  {
    path: "linked-in-pages",
    loadChildren: () =>
      import("../../components/linked-in-pages/linked-in-pages.module").then(
        (m) => m.LinkedInPagesModule
      ),
  },
  {
    path: "linkedin-connected-pages",
    loadChildren: () =>
      import(
        "../../components/linkedin-connected-pages/linkedin-connected-pages.module"
      ).then((m) => m.LinkedinConnectedPagesModule),
  },
  {
    path: "leads",
    loadChildren: () =>
      import("../../components/leads/leads.module").then((m) => m.LeadsModule),
  },
  {
    path: "leads/add-leads",
    loadChildren: () =>
      import("../../components/add-leads/add-leads.module").then(
        (m) => m.AddLeadsModule
      ),
  },

  {
    path: "leads/update-leads",
    loadChildren: () =>
      import("../../components/update-leads/update-leads.module").then(
        (m) => m.UpdateLeadsModule
      ),
  },

  {
    path: "leads/lead-tags",
    loadChildren: () =>
      import("../../components/lead-tags/lead-tags.module").then(
        (m) => m.LeadTagsModule
      ),
  },
  {
    path: "leads/add-lead-tags",
    loadChildren: () =>
      import("../../components/add-lead-tags/add-lead-tags.module").then(
        (m) => m.AddLeadTagsModule
      ),
  },
  {
    path: "leads/update-lead-tags",
    loadChildren: () =>
      import("../../components/update-lead-tags/update-lead-tags.module").then(
        (m) => m.UpdateLeadTagsModule
      ),
  },
  {
    path: "leads/lead-source",
    loadChildren: () =>
      import("../../components/lead-source/lead-source.module").then(
        (m) => m.LeadSourceModule
      ),
  },
  {
    path: "leads/add-lead-source",
    loadChildren: () =>
      import("../../components/add-lead-source/add-lead-source.module").then(
        (m) => m.AddLeadSourceModule
      ),
  },
  {
    path: "leads/update-lead-source",
    loadChildren: () =>
      import(
        "../../components/update-lead-source/update-lead-source.module"
      ).then((m) => m.UpdateLeadSourceModule),
  },
  {
    path: "leads/lead-stage",
    loadChildren: () =>
      import("../../components/lead-stage/lead-stage.module").then(
        (m) => m.LeadStageModule
      ),
  },
  {
    path: "leads/add-lead-stage",
    loadChildren: () =>
      import("../../components/add-lead-stage/add-lead-stage.module").then(
        (m) => m.AddLeadStageModule
      ),
  },
  {
    path: "leads/update-lead-stage",
    loadChildren: () =>
      import(
        "../../components/update-lead-stage/update-lead-stage.module"
      ).then((m) => m.UpdateLeadStageModule),
  },
  {
    path: "upload-csv",
    loadChildren: () =>
      import("../../components/upload-csv/upload-csv.module").then(
        (m) => m.UploadCsvModule
      ),
  },
];
