import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  name?: string;
  visible?:boolean
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  private orgId: string | null = localStorage.getItem('org_id');


  MENUITEMS: Menu[] = [
    {
      path: "/dashboard",
      title: "UserDashboard",
      icon: "calender",
      name: this.orgId,
      type: "link",
      visible: true,
      bookmark: true
    },
    {
    //  path: "/admindashboard",
      title: "AdminDashboard",
      icon: "calender",
      type: "sub",
      name: this.orgId,
      active: false,
      visible: false, // Default to false
      children: [
        { path: "/admindashboard/admindashboard", title: "Dashboard", icon: "user", type: "link", visible: true,  bookmark: true, },
        { path: "admindashboard/report", title: "Report", icon: "user", type: "link",visible: true,  bookmark: true, },
        { path: "admindashboard/graph", title: "Graph", icon: "user", type: "link",visible: true,  bookmark: true, },
        { path: "admindashboard/plan-details", title: "Plan Details", icon: "user", type: "link",visible: true,  bookmark: true, },
        { path: "admindashboard/coupon-code", title: "Coupon Code", icon: "user", type: "link",visible: true,  bookmark: true, },
        { path: "admindashboard/google-map", title: "CheckIn Map", icon: "user", type: "link",visible: true,  bookmark: true, },
      ],
    },
    {
      title: "Organizations",
      icon: "widget",
      type: "sub",
      name: this.orgId,
      badgeType: "light-secondary",
      active: false,
      visible: true,
      children: [
        { path: "organization/members-in-organization", title: "Members", icon: "user", type: "link", visible: true, bookmark: true, },
        { path: "organization/org-members", title: "Member Management", icon: "user", type: "link", visible: true, bookmark: true, },

      ],
    },
    {
      title: "Leads",
      icon: "widget",
      type: "sub",
      name: this.orgId,
      badgeType: "light-secondary",
      active: false,
      visible: true,
      children: [
        { path: "/leads", title: "Leads", icon: "user", type: "link", visible: true, bookmark: true, },
        { path: "/leads/lead-source", title: "Lead Source", icon: "user", type: "link", visible: true, bookmark: true, },
        { path: "/leads/lead-stage", title: "Lead Stage", icon: "user", type: "link", visible: true, bookmark: true, },
        { path: "/leads/lead-tags", title: "Lead Tag", icon: "user", type: "link", visible: true, bookmark: true, },
      ], 
    },
    {
      path: "/integrations",
      title: "Reports",
      icon: "charts",
      type: "link",
      visible: true,
      bookmark: true

    },
    // {
    //   path: "/integrations",
    //   title: "Integrations",
    //   icon: "charts",
    //   type: "link",
    //   bookmark: true

    // },

    {
      title: "Integrations",
      icon: "charts",
      type: "sub",
      name: this.orgId,
      badgeType: "light-secondary",
      active: false,
      visible: true,
      children: [
        { path: "integrations", title: "Integrations", icon: "user", type: "link", visible: true, bookmark: true, },
        { path: "upload-csv", title: "Upload CSV", icon: "user", type: "link", visible: true, bookmark: true, },

      ],
    },



  ];

  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
