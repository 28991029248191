<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-md-auto">
        <h3 style="color: #7EA3CF;">{{ title | translate}}</h3>
        <h6 style="color: rgb(110, 110, 109);">{{subTitle | translate }}</h6>
      </div>
      <div class="col-md-1">
        <button class="btn my-button" (click)="switchtToCreateOrgJoin()">
          <img src="../../../../assets/icon/Vector.png" height="18px" style="margin-left: -10px;
          margin-top: -5px;">
        </button>
      </div>
      <div class="col-md-auto">
        <span style="margin-left: -45%;"><strong style="color: #3285FF;">Switch</strong></span>
      </div>
    </div>
  </div>
</div>