import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  // public profileImg: "assets/images/dashboard/profile.jpg";
  firstName: any;
  profileImg: any
  lang: string;
  firstLetter: any;
  constructor(public router: Router, private translate: TranslateService) {
    this.lang = localStorage.getItem('lang_code')
    console.log(this.lang);
    if (this.lang == null) {
      this.lang = 'en'
      localStorage.setItem('lang_code', this.lang)
    }
    this.translate.use(this.lang)
    if (JSON.parse(localStorage.getItem("user"))) {
    } else {
    }
  }

  async ngOnInit() {
    
    this.firstName = await localStorage.getItem('user_firstName');
    console.log(' this.firstName---->>', this.firstName)
    this.firstLetter = this.firstName ? this.firstName.charAt(0).toUpperCase() : '';
    if (!this.profileImg) {
      this.firstLetter = this.firstName ? this.firstName.charAt(0).toUpperCase() : '';
      console.log('Display:', this.firstLetter);
    } else {
      console.log('Profile image is available:', this.profileImg);
    }
  }

  logoutFunc() {
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
  }
}
