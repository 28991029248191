import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginQRData } from '../models/loginQrData';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  isAuthenticated : Boolean;
  BaseUrl = 'https://api.cuelab.net/api/';
  headers: Headers
 
  constructor(
    private _httpClient: HttpClient
  ) { 
    this.headers = new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
  }
 
  saveLoginQRData(qrData: LoginQRData) {
    return this._httpClient.post(this.BaseUrl + 'Registration/SaveQrData', qrData);
  }
 
  checkUserVerification(QRData:LoginQRData) {
    return this._httpClient.post(this.BaseUrl + 'Registration/checkverificationprogress',QRData)
  }

  logout(userIpAddress: any) {
    console.log(userIpAddress);
    return this._httpClient.post(this.BaseUrl + 'qr-code/logout', userIpAddress);
  }

  userDetail(mob: any) {
    return this._httpClient.post(this.BaseUrl + 'auth/user/', mob);
  }

}
