import { Component, OnInit } from '@angular/core';
import { NavService } from '../../../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Español',
    code: 'es',
    icon: 'es',
    type: ''
  },
  {
    language: 'Français',
    code: 'fr',
    icon: 'fr',
    type: ''
  },
  {
    language: 'Português',
    code: 'pt',
    type: 'BR',
    icon: 'pt'
  },
  {
    language: 'German',
    code: 'gr',
    type: 'de',
    icon: 'de'
  },
  {
    language: 'Hindi',
    code: 'hi',
    type: 'india',
    icon: 'in'
  },
    {
    language: 'Marathi',
    code: 'mr',
    type: 'india',
    icon: 'in'
  }]

  selectedLanguage: any

  constructor(public navServices: NavService, private translate: TranslateService) { }
  // 
  async ngOnInit() {
   
    const lang_lang = await localStorage.getItem('lang_lang')
    const lang_code = await localStorage.getItem('lang_code')
    const lang_type = await localStorage.getItem('lang_type')
    const lang_icon =await localStorage.getItem('lang_icon')

    const lan = {
      language: lang_lang,
      code: lang_code,
      type: lang_type,
      icon: lang_icon
    }
    console.log("lang : ", lan);
    if (lang_lang) {
      this.selectedLanguage = lan
    }
    else {
      const lan = {
        language: 'English',
        code: 'en',
        type: 'US',
        icon: 'us'
      }
      this.selectedLanguage = lan
      console.log("this.selectedLanguage : ",this.selectedLanguage);
      
    }
  }

  async changeLanguage(language: any) {
     const lang_lang = language.language
     const lang_code = language.code
     const lang_type = language.type
     const lang_icon = language.icon
    await localStorage.setItem('lang_lang', lang_lang)
    await localStorage.setItem('lang_code', lang_code)
    await localStorage.setItem('lang_type', lang_type)
    await localStorage.setItem('lang_icon', lang_icon)
    this.translate.use(language.code)
    this.selectedLanguage = language;
    window.location.reload()
  }

}
