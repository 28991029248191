import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  BaseUrl = 'https://api.cuelab.net/api/';


  constructor( private _httpClient: HttpClient) { }

  getOrganizatonsData(UserId:any){
    return this._httpClient.get(this.BaseUrl+'Organizations/GetOrganizationByUserId?UserId='+UserId)
  }
}
