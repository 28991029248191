export class LoginQRData {
 
    id: string;
    userIpAddress: string;
    secString: string;
    userMobileNo: string;
    isVerfied: number;
    browser: any;
    browserVersion: any;
    os: any;
    osVersion: any;
    ipAddress:any;
}
